<template>
  <div>
    <DimsTooltip :dark="index === 0 && isOfferConditional" @click.stop="openDialog()">
      <p>Klik for at se dokumentation af udregningen.</p>
    </DimsTooltip>
    <v-dialog v-model="bpqDocumentationDialog">
      <v-container fluid>
        <v-card class="pa-6">
          <v-container>
            <h1>Udregning af pris pr. kvalitetspoint</h1>
            <br />
            <v-card-text class="pl-0">
              <h3 class="mt-0">Vægtning</h3>
              <div class="d-flex mt-0">
                <ul>
                  <li v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <strong>{{ criteria.label }}</strong>
                      <div class="ml-1">
                        {{ displayWith1Decimal(criteria.weight) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3 class="mt-6">Point</h3>
              <div class="d-flex mt-0">
                <div>
                  <h4 class="mt-2">Score</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <strong>{{ criteria.label }}:</strong>
                      <div class="ml-1">
                        {{ criteria.score }} point
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-12">
                  <h4 class="mt-2">Vægtet</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <div class="ml-1">
                        {{
                          `${displayWith1Decimal(criteria.weight)}
                          x ${criteria.score}
                            = ${displayWith1Decimal(criteria.weightedScore)} point`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="d-flex">
                <strong>Samlet antal vægtet point:</strong>
                <div class="ml-1">
                  {{ `${criterias.length > 1 ? `${totalWeightedScoreString} = ` : ''}${displayWith1Decimal(offer.data.bpqPointScore ?? 0)} point` }}
                </div>
              </div>
              <div v-if="isOfferConditional">
                <h3 class="mt-6">Resultat (pris pr. kvalitetspoint)</h3>
                <v-row class="mt-0">
                  <v-col class="pt-1">
                    <div>
                      <i>
                        Evalueringsteknisk pris / Tilbuddets kvalitetspoint
                      </i>
                      <br />
                      {{ `${$format.currency2(offer.data.tcoCalculationResult?.tcoPrice)} / ${offer.data.bpqPointScore} point = ` }}
                      <span class="font-weight-bold double-underline ml-1">
                        {{ `${$format.currency2(offer.data.bpqScore)} / point` }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <h3 class="mt-6">Ukonditionelt tilbud</h3>
                <v-row class="mt-0">
                  <v-col class="pt-1 text-error">
                    {{ unconditionalOfferText }}
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <div class="d-flex justify-center">
              <v-card-actions>
                <v-btn class="secondary-button" @click="closeDialog()">
                  Luk
                </v-btn>
              </v-card-actions>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">

import { ref, computed } from 'vue';
import { OfferEvaluation, WeightedScoreViewModel } from '@dims/components';
import { Offer0222 } from '@/models/Offer';
import { Tender0222 } from '@/models/Tender';
import bpqCriteria from '@/services/bpqCriteria';

const { offer, tender, offerEvaluation, index } = defineProps<{
  offer: Offer0222,
  tender: Tender0222,
  offerEvaluation?: OfferEvaluation,
  index: number,
  isOfferConditional: boolean,
}>();

const bpqDocumentationDialog = ref(false);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const criterias = ref<WeightedScoreViewModel[]>(null!);

function openDialog() {
  bpqDocumentationDialog.value = true;

  if (offer.isEvaluated && offerEvaluation) {
    criterias.value = bpqCriteria.getCategories(tender.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  } else {
    criterias.value = bpqCriteria.getEmptyCategories(tender.data)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }
}

function closeDialog() {
  bpqDocumentationDialog.value = false;
}

const totalWeightedScoreString = computed(() => {
  const values = criterias.value.map((c) => displayWith1Decimal(c.weightedScore));
  return values.join(' + ');
});

const unconditionalOfferText = computed(() => {
  const offerScore = offer.data.bpqPointScore;

  if (offer.isUnconditional) {
    return `Tilbuddet er markeret som ukonditionelt af ordregiver (det har opnået ${offerScore} point ifht. indkøbets krævede min. antal point ${tender.data.bpqPointScoreMinimum} point).`;
  }

  if (!offer.specificationAccepted) {
    return 'Tilbuddet er markeret som ukonditionelt af tilbudsgiver';
  }

  return `Tilbuddet er blevet forkastet, da det har opnået ${offerScore} point hvilket er mindre end indkøbets krævede min. antal point (${tender.data.bpqPointScoreMinimum} point).`;
});

function displayWith1Decimal(value: number) {
  return value.toFixed(1).replace('.', ',');
}
</script>

<style scoped>

.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}

ul {
  list-style-type: square;
}
</style>
