import { AwardCriteriaType, Offer, Tender } from '@dims/components';
import { Offer0222, Tender0222 } from '@/models';
import { awardCriteriaType } from '@/services';

function awardTypeLabel(type?: AwardCriteriaType | null): string {
  return type ? awardCriteriaType.getLabel(type) : '';
}

export default {
  offerLabels(offer: Offer0222) {
    const { tenderData } = offer;
    return [awardTypeLabel(tenderData.awardType)];
  },
  offerDetails(_offer: Offer) {
    return '';
  },
  offerLongDescription(offer: Offer) {
    const { tenderData } = offer as Offer0222;
    return `${tenderData.description}`;
  },
  tenderLongDescription(tender: Tender) {
    return tender.description;
  },
  tenderValues(tender: Tender0222): { label: string, name: string, value: string }[] {
    return [
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tender.awardCriteriaType) },
    ];
  },
  offerValues(offer: Offer0222): { label: string, name: string, value: string }[] {
    const { tenderData } = offer;
    return [
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tenderData.awardType) },
    ];
  },
  getColumnValues(tender: Tender) {
    return [awardTypeLabel(tender.awardCriteriaType)];
  },
  getTableHeaders() {
    return [{
      title: 'Tildelingskriterie',
      align: 'start' as const,
      value: 'awardType',
      sort: (a: string, b: string) => awardCriteriaType.getLabel(a as AwardCriteriaType)
        .localeCompare(awardCriteriaType.getLabel(b as AwardCriteriaType)),
      sortable: true,
    }];
  },
  searchOffersText(search: string, offers: Offer0222[]): Offer[] {
    const searchString = search.toLowerCase();
    return offers.filter(
      (o) => o.sender.companyName
        .concat(o.tenderData.description ?? '') // TODO: Concatenate all text fields?
        .toLowerCase()
        .includes(searchString),
    );
  },
};
